<template>
    <div class="order-info-wrapper">
        <a-descriptions title="订单概况">
            <a-descriptions-item label="订单编号">
                {{ orderInfo.orderNo }}
            </a-descriptions-item>
            <a-descriptions-item label="订单状态">
                {{ orderState }}
            </a-descriptions-item>
            <a-descriptions-item label="下单时间">
                {{ orderInfo.createTime }}
            </a-descriptions-item>
            <a-descriptions-item label="实付金额">
                ￥{{ orderInfo.payMoney }}
            </a-descriptions-item>
            <a-descriptions-item label="支付状态">
                {{ payState }}
            </a-descriptions-item>
            <a-descriptions-item label="支付时间">
                {{ orderInfo.payTime }}
            </a-descriptions-item>
            <a-descriptions-item label="待支付原因">
                {{ orderInfo.orderErr }}
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
export default {
    name: "OrderInfo",
    props: {
        orderInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        orderState() {
            let txt = '';

            switch (this.orderInfo.orderStatus) {
                case -2:
                    txt = '待确认';
                    break;
                case -1:
                    txt = '已确认';
                    break;
                case 0:
                    txt = '待支付';
                    break;
                case 1:
                    txt = '已支付';
                    break;
                case 2:
                    txt = '支付分失败';
                    break;
                case 3:
                    txt = '扣款中';
                    break;
                case 4:
                case -3:
                    txt = '已关闭';
                    break;
                case 5:
                    txt = '售后中';
                    break;
                case 6:
                    txt = '售后完成';
                    break;
                case 7:
                    txt = '售后驳回';
                    break;
            }

            return txt
        },

        payState() {
            let pay_status = '';

            switch (this.orderInfo.payStatus) {
                case -1:
                    pay_status = '强制完结';
                    break;
                case 0:
                    pay_status = '待支付';
                    break;
                case 1:
                    pay_status = '已支付';
                    break;
                case 2:
                    pay_status = '进行中';
                    break;
                case 3:
                    pay_status = '关闭支付';
                    break;
                case 4:
                    pay_status = '订单催收';
                    break;
            }

            return pay_status
        }
    }
};
</script>

<style lang="less" scoped>
</style>