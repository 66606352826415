<template>
    <div class="order-detail-wrapper">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="order-data">
            <!-- 订单概况 -->
            <order-info :order-info="orderInfo" />

            <!-- 用户信息 -->
            <user-info :user-info="userInfo" />

            <!-- 商家信息 -->
            <business-info :business-info="businessInfo" />

            <!-- 收费信息 -->
            <pay-info :pay-info="payInfo" />

            <!-- 商品信息 -->
            <goods-info :goodsList="goodsList" />

            <div class="btns">
                <a-button class="btn" type="primary" @click="goBack"
                    >返回</a-button
                >
                <a-button
                    class="btn"
                    :loading="loading"
                    type="primary"
                    @click="onRefresh"
                    >刷新</a-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import OrderInfo from "./childComps/OrderInfo";
import UserInfo from "./childComps/UserInfo";
import BusinessInfo from "./childComps/OrderBusinessInfo";
import PayInfo from "./childComps/PayInfo";
import GoodsInfo from "./childComps/GoodsInfo";

import Breadcrumb from "components/breadcrumb/Breadcrumb";

import { getOrderDetail } from "network/orders";

export default {
    name: "OrderDetail", // 订单详情
    components: {
        OrderInfo,
        UserInfo,
        BusinessInfo,
        PayInfo,
        GoodsInfo,

        Breadcrumb,
    },
    data() {
        return {
            orderId: "", // 订单id

            breadcrumb: [
                {
                    path: "",
                    breadcrumbName: "首页",
                },
                {
                    path: "/ordersManage",
                    breadcrumbName: "订单管理",
                    type: "go",
                    back: -1,
                },
                {
                    path: "/orderDetail",
                    breadcrumbName: "订单详情",
                },
            ],

            orderInfo: {}, // 订单概况
            userInfo: {}, // 用户信息
            businessInfo: {}, // 商家信息
            payInfo: {}, // 收费信息
            goodsList: [], // 商品列表

            loading: false,
        };
    },
    created() {
        this.orderId = this.$route.query.id;

        this._getOrderDetail(this.orderId);
    },
    methods: {
        // ----------------事件处理函数---------------
        // 返回
        goBack() {
            this.$router.back();
        },
        // 刷新
        onRefresh() {
            this._getOrderDetail(this.orderId);
        },

        // ----------------网络请求----------------
        // 获取订单详情信息
        _getOrderDetail(id) {
            this.loading = true;

            getOrderDetail(id)
                .then((res) => {
                    const data = res.data;

                    // 订单概况
                    this.orderInfo = {
                        orderNo: data.orderNo,
                        orderStatus: data.orderStatus,
                        createTime: data.createTime,
                        payMoney: data.payAmount,
                        payStatus: data.payStatus,
                        orderErr: data.wechatOrderErrDes,
                        payTime: data.payTime,
                    };

                    // 用户信息
                    this.userInfo = {
                        avatar: data.userAvatar,
                        name: data.realName,
                        phone: data.userPhone,
                        status: data.userClosed,
                    };

                    // 商家信息
                    this.businessInfo = {
                        name: data.storeOperatorName,
                        username: data.storeUserName,
                        storeName: data.storeName,
                        machineName: data.deviceName,
                        machineNo: data.deviceNo,
                        machineType: data.deviceType,
                        openDoor: data.location,
                    };

                    // 收费信息
                    this.payInfo = {
                        goodsPrice: data.orderAmount,
                        orderMoney: data.orderAmount,
                        discountMoney: data.discountAmount,
                        payMoney: data.payAmount,
                        payNo: data.wechatOrderId,
                        businessNo: data.merchantNo,
                        incomeNumber: data.receivingAccount,
                        payType: data.payType,
                        payTime: data.payTime,
                    };

                    // 商品信息
                    let goodsList = [];

                    class Goods {
                        constructor(obj) {
                            for (const key in obj) {
                                this[key] = obj[key];
                            }
                        }
                    }

                    data.goodsList.forEach(item => {
                        let goods = new Goods({
                            key: item.id,
                            count: item.goodsAmount,
                            totalPrice: item.totalPrice,
                            unit: item.goodsSaleType == 0 ? item.goodsSaleUnit : '克',
                            oneWeight: item.goodsWeight,
                            saleType: item.goodsSaleType == 0 ? '按数量' : '按重量',
                            saleChannel: `货道${ item.channelNo }`,
                            startWeight: item.openWeight,
                            endWeight: item.closeWeight,
                            weight: item.differenceWeight,
                            ...item,
                        });

                        for (const key in goods) {
                            !goods[key] && goods[key] != 0 && (goods[key] = "暂无数据");
                        }

                        goodsList.push(goods);
                    })
                    this.goodsList = goodsList;

                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
.order-detail-wrapper {
    width: 100%;
    // height: 100%;
    position: relative;

    .order-data {
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;

        .btns {
            margin-top: 20px;

            .btn {
                margin-right: 20px;
            }
        }
    }
}
</style>