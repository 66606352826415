<template>
    <div class="user-info-wrapper">
        <a-descriptions title="用户信息">
            <a-descriptions-item>
                <div class="user-msg">
                    <div class="avatar">
                        <img :src="userInfo.avatar" alt="" />
                    </div>

                    <div class="msg">
                        <div class="nickname one-txt-cut">
                            <span>{{ userInfo.name }}</span>
                        </div>
                        <div class="phone">
                            <span>手机号：{{ userInfo.phone }}</span>
                        </div>
                    </div>
                </div>
            </a-descriptions-item>

            <a-descriptions-item label="用户状态">
                {{ userState }}
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
export default {
    name: "UserInfo", // 用户详情
    props: {
        userInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        userState() {
            let txt = "";

            switch (this.userInfo.status) {
                case 0:
                    txt = "正常";
                    break;
                case 1:
                    txt = "拉黑";
                    break;
            }

            return txt
        },
    },
};
</script>

<style lang="less" scoped>
.user-info-wrapper {
    padding: 20px 0 0;
    border-top: 1px solid #eee;

    .user-msg {
        width: 400px;
        display: flex;
        align-items: center;
        overflow: hidden;

        .avatar {
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 15px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .msg {
            flex: 1;
            overflow: hidden;
        }
    }
}
</style>