<template>
    <div class="pay-info-wrapper">
        <a-descriptions title="收费信息">
            <a-descriptions-item label="商品总价（元）">
                {{ payInfo.goodsPrice }}
            </a-descriptions-item>
            <a-descriptions-item label="订单金额（元）">
                {{ payInfo.orderMoney }}
            </a-descriptions-item>
            <a-descriptions-item label="优惠金额（元）">
                {{ payInfo.discountMoney }}
            </a-descriptions-item>
            <a-descriptions-item label="实付金额（元）">
                {{ payInfo.payMoney }}
            </a-descriptions-item>
            <a-descriptions-item label="交易单号">
                {{ payInfo.payNo }}
            </a-descriptions-item>
            <a-descriptions-item label="商户单号">
                {{ payInfo.businessNo }}
            </a-descriptions-item>
            <a-descriptions-item label="收款账号">
                {{ payInfo.incomeNumber }}
            </a-descriptions-item>
            <a-descriptions-item label="支付方式">
                {{ payType }}
            </a-descriptions-item>
            <a-descriptions-item label="支付时间">
                {{ payInfo.payTime }}
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
export default {
    name: "PayInfo",
    props: {
        payInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        payType() {
            let txt = "";

            switch (this.payInfo.payType) {
                case -1:
                    txt = "强制完结";
                    break;
                case 0:
                    txt = "微信支付分";
                    break;
                case 1:
                    txt = "微信支付";
                    break;
            }

            return txt
        },
    },
};
</script>

<style lang="less" scoped>
.pay-info-wrapper {
    padding: 20px 0 0;
    border-top: 1px solid #eee;
}
</style>