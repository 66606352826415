<template>
    <div class="goods-info-wrapper">
        <a-descriptions title="商品信息" :column="1">
            <div class="goods-list no-conversion">
                <a-table
                    :columns="columns"
                    :data-source="goodsList"
                    :pagination="false"
                    :scroll="scroll"
                    :row-key="(record) => record.id"
                >
                    <div slot="goodsImage" slot-scope="record">
                        <a
                            v-if="record"
                            :href="$fileUrl + record"
                            target="_blank"
                        >
                            <img class="goods-img" :src="$fileUrl + record" alt="" />
                        </a>
                    </div>

                    <div slot="weight" slot-scope="record">
                        <span :style="record > 0 ? 'color: red;' : ''">{{ record }}</span>
                    </div>
                </a-table>
            </div>
        </a-descriptions>
    </div>
</template>

<script>
export default {
    name: "GoodsInfo",
    props: {
        goodsList: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            columns: [
                {
                    title: "商品图",
                    dataIndex: "goodsImage",
                    key: "goodsImage",
                    width: 130,
                    fixed: "left",
                    scopedSlots: { customRender: "goodsImage" },
                },
                {
                    title: "商品名称",
                    dataIndex: "goodsName",
                    key: "goodsName",
                    width: 150,
                    fixed: "left",
                },
                {
                    title: "商品售价（元）",
                    dataIndex: "goodsPrice",
                    key: "goodsPrice",
                    width: 130,
                },
                {
                    title: "商品数量",
                    dataIndex: "count",
                    key: "count",
                    width: 130,
                },
                {
                    title: "商品总价",
                    dataIndex: "totalPrice",
                    key: "totalPrice",
                    width: 130,
                },
                {
                    title: "计量单位",
                    dataIndex: "unit",
                    key: "unit",
                    width: 130,
                },
                {
                    title: "单个重量（克）",
                    dataIndex: "oneWeight",
                    key: "oneWeight",
                    width: 130,
                },
                {
                    title: "售卖方式",
                    dataIndex: "saleType",
                    key: "saleType",
                    width: 130,
                },
                {
                    title: "售卖货道",
                    dataIndex: "saleChannel",
                    key: "saleChannel",
                    width: 130,
                },
                {
                    title: "货道开门前重量(克)",
                    dataIndex: "startWeight",
                    key: "startWeight",
                    width: 180,
                },
                {
                    title: "货道关门后重量(克)",
                    dataIndex: "endWeight",
                    key: "endWeight",
                    width: 180,
                },
                {
                    title: "重量差(克)",
                    dataIndex: "weight",
                    key: "weight",
                    width: 130,
                    scopedSlots: { customRender: "weight" },
                },
            ],
            scroll: {
                x: true,
                y: 440,
            },
        };
    },
};
</script>

<style lang="less" scoped>
.goods-info-wrapper {
    width: 100%;
    padding: 20px 0 0;
    border-top: 1px solid #eee;

    .goods-list {
        width: 100%;
        overflow: auto;
    }

    .goods-img {
        width: 100px;
        height: 100px;
        overflow: hidden;
    }
}
</style>