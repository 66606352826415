<template>
    <div class="order-business-info">
        <a-descriptions title="商家信息">
            <a-descriptions-item label="商家名称">
                {{ businessInfo.name }}
            </a-descriptions-item>
            <a-descriptions-item label="商家账号">
                {{ businessInfo.username }}
            </a-descriptions-item>
            <a-descriptions-item label="门店名称">
                {{ businessInfo.storeName }}
            </a-descriptions-item>
            <a-descriptions-item label="货柜名称">
                {{ businessInfo.machineName }}
            </a-descriptions-item>
            <a-descriptions-item label="货柜编号">
                {{ businessInfo.machineNo }}
            </a-descriptions-item>
            <a-descriptions-item label="柜体类型">
                {{ machineType }}
            </a-descriptions-item>
            <a-descriptions-item label="本次开门">
                {{ openDoor }}
            </a-descriptions-item>
        </a-descriptions>
    </div>
</template>

<script>
export default {
    name: "OrderBusinessInfo", // 商家信息
    props: {
        businessInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        machineType() {
            let txt = "";

            switch (this.businessInfo.machineType) {
                case 0:
                    txt = "单门";
                    break;
                case 1:
                    txt = "双门";
                    break;
            }

            return txt;
        },

        openDoor() {
            let txt = "";

            switch (this.businessInfo.openDoor) {
                case 0:
                    txt = "单门";
                    break;
                case 1:
                    txt = "左侧货柜";
                    break;
                case 2:
                    txt = "右侧货柜";
                    break;
            }

            return txt
        },
    },
};
</script>

<style lang="less" scoped>
.order-business-info {
    padding: 20px 0 0;
    border-top: 1px solid #eee;
}
</style>